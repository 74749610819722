import React from 'react'
import { Token } from '@pancakeswap-libs/sdk'
import { Menu as UikitMenu } from '@impossiblefinance/uikit'
import { useWeb3React } from '@web3-react/core'
import config from './config'
import { useETHBalances, useTokenBalance } from '../../state/wallet/hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import useAuth from '../../hooks/useAuth'
import useStaxPrice from '../../hooks/useStaxPrice'
import tokenList from '../../constants/tokenList'

const Menu: React.FC = props => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const [isDark, toggleTheme] = useDarkModeManager()
  const useBNBBalanceCallback = () => useETHBalances(account ? [account] : [])?.[account ?? '']?.toSignificant(4) ?? ''
  const useIFBalanceCallback = () => {
    const token = new Token(56, tokenList.IF.address, 18, tokenList.IF.name)
    return useTokenBalance(account ?? undefined, token)?.toSignificant(4) ?? ''
  }
  let staxPrice = useStaxPrice()
  let formattedIFPrice
  if (staxPrice && staxPrice.greaterThan('0')) {
    formattedIFPrice = Number(staxPrice.toFixed(4))
  }

  return (
    <UikitMenu
      account={account ?? undefined}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang="en"
      setLang={() => {}}
      langs={[]}
      links={config}
      useBnbBalance={useBNBBalanceCallback}
      useIFBalance={useIFBalanceCallback}
      ifPriceUsd={formattedIFPrice}
      {...props}
    />
  )
}

export default Menu
