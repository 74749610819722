type TokenInformation = {
  address: string,
  name: string
}

const tokenList: {[name: string]: TokenInformation} = {
  STAX: {
    address: "0x0da6ed8b13214ff28e9ca979dd37439e8a88f6c4",
    name: "STAX"
  },
  IF: {
    address: "0xb0e1fc65c1a741b4662b813eb787d369b8614af1",
    name: "IF"
  },
  BUSD: {
    address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    name: "BUSD"
  }
}

export default tokenList;