import React from 'react'
import styled from 'styled-components'
import Settings from '../components/Settings'

export const HeaderWrapper = styled.div`
  padding: 24px 0;
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

const HeaderTitle = styled.h3`
  font-size: 28px;
  font-weight: 500;
  margin: 0;
`

const HeaderDescription = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text2};
`

type AppHeaderProps = {
  title: string
  description: string
  showSettings?: boolean
}
export default function AppHeader({ title, description, showSettings }: AppHeaderProps) {
  return (
    <HeaderWrapper>
      <TitleWrapper>
        <HeaderTitle>{title}</HeaderTitle>
        {showSettings && <Settings />}
      </TitleWrapper>
      <HeaderDescription>{description}</HeaderDescription>
    </HeaderWrapper>
  )
}
