import React from 'react'
import styled from 'styled-components'

import { Text } from 'rebass'
import { BlueCardStyled } from '../Card'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`
type InformationBannerProps = {
  text: string
}

const InformationBanner: React.FC<InformationBannerProps> = ({ text }) => {
  return (
    <BlueCardStyled>
      <ContentWrapper>
        <img src="/images/Alert.svg" alt="alert" />
        <Text marginLeft="8px" fontSize="12px">
          {text}
        </Text>
      </ContentWrapper>
    </BlueCardStyled>
  )
}

export default InformationBanner
