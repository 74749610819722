import { useActiveWeb3React } from ".";
import tokenList from "../constants/tokenList";
import { usePair } from "../data/Reserves";
import { wrappedCurrency } from "../utils/wrappedCurrency";
import { useCurrency } from "./Tokens";

const useStaxPrice = () => {
  const { chainId } = useActiveWeb3React()
  const ifCoin = useCurrency(tokenList.IF.address) ?? undefined
  const busd = useCurrency(tokenList.BUSD.address) ?? undefined
  const wrappedIF = wrappedCurrency(ifCoin, chainId)
  const [, pair] = usePair(ifCoin, busd);
  return pair && pair.priceOf(wrappedIF)
}

export default useStaxPrice;