import React, { useContext, useMemo } from 'react'
import { ThemeContext } from 'styled-components'
import { Pair } from '@pancakeswap-libs/sdk'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Question from '../../components/QuestionHelper'
import FullPositionCard from '../../components/PositionCard'
import { useUserHasLiquidityInAllTokens } from '../../data/V1'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { StyledInternalLink, TYPE } from '../../components/Shared'
import { Text } from 'rebass'
import { ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'

import { useActiveWeb3React } from '../../hooks'
import { usePairs } from '../../data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import AppBody from '../AppBody'
import { Dots } from '../../components/swap/styleds'
import TranslatedText from '../../components/TranslatedText'
import { TranslateString } from '../../utils/translateTextHelpers'
import AppHeader from '../AppHeader'
import { LightGreyCard } from '../../components/Card'
import InformationBanner from '../../components/InformationBanner'

const TutorialItem = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 13px;
`

const TutorialItemText = styled.div`
  margin-left: 5px;
`
const TutorialItemNumber = styled.div`
  background: ${({ theme }) => theme.colors.ifPrimary};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`
const LiquidityTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const FooterContainer = styled.div`
  margin-top: 74px;
`
export default function Pool() {
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  const hasV1Liquidity = useUserHasLiquidityInAllTokens()

  return (
    <>
      <AppBody>
        <AppHeader title="Liquidity" description="Add liquidity to receive LP tokens." showSettings />
        <AutoColumn gap="16px">
          <InformationBanner text="Any liquidity added and shown here is native to Impossible. LP tokens from other platforms like Pancake is not reflected here, please go to the respective platform to view your LP tokens." />
          <LightGreyCard>
            <AutoColumn gap="12px">
              <TutorialItem>
                <div>
                  <TutorialItemNumber>1</TutorialItemNumber>
                </div>
                <TutorialItemText>
                  Adding liquidity will give you LP tokens that earns you fees proportional to your share when a swap
                  between the pair is made. You can remove liquidity any time.
                </TutorialItemText>
              </TutorialItem>
              <TutorialItem>
                <div>
                  <TutorialItemNumber>2</TutorialItemNumber>
                </div>
                <TutorialItemText>
                  You can stake these LP tokens received when you add liquidity to earn rewards (often much more than
                  fees).
                </TutorialItemText>
              </TutorialItem>
            </AutoColumn>
          </LightGreyCard>

          <AutoColumn gap="27px" justify="center">
            <ButtonPrimary id="join-pool-button" as={Link} style={{ padding: 16 }} to="/add/BUSD">
              <TranslatedText translationId={100}>Add Liquidity</TranslatedText>
            </ButtonPrimary>

            <AutoColumn gap="12px" style={{ width: '100%' }}>
              <LiquidityTitle>
                <Text color={theme.colors.text1} fontSize="22px" fontWeight={500}>
                  <TranslatedText translationId={102}>Your Liquidity</TranslatedText>
                </Text>

                <Question
                  text={TranslateString(
                    130,
                    'When you add liquidity, you are given pool tokens that represent your share. If you don’t see a pool you joined in this list, try importing a pool below.'
                  )}
                />
              </LiquidityTitle>

              {!account ? (
                <TYPE.body color={theme.colors.text3} textAlign="center">
                  Connect to a wallet to view your liquidity.
                </TYPE.body>
              ) : v2IsLoading ? (
                <TYPE.body color={theme.colors.text3} textAlign="center">
                  <Dots>Loading</Dots>
                </TYPE.body>
              ) : allV2PairsWithLiquidity?.length > 0 ? (
                <>
                  {allV2PairsWithLiquidity.map(v2Pair => (
                    <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                  ))}
                </>
              ) : (
                <TYPE.body color={theme.colors.text3} textAlign="center">
                  <TranslatedText translationId={104}>No liquidity found.</TranslatedText>
                </TYPE.body>
              )}

              <FooterContainer>
                <Text textAlign="left" fontSize={14} style={{ padding: '.5rem 0 .5rem 0' }}>
                  {hasV1Liquidity
                    ? 'Uniswap V1 liquidity found!'
                    : TranslateString(106, "Don't see a pool you joined?")}{' '}
                  <StyledInternalLink id="import-pool-link" to={hasV1Liquidity ? '/migrate/v1' : '/find'}>
                    {hasV1Liquidity ? 'Migrate now.' : TranslateString(108, 'Import it')}
                  </StyledInternalLink>
                  {hasV1Liquidity ? (
                    ''
                  ) : (
                    <>
                      <br />
                      <br />
                      Or if your LP tokens are staked somewhere else, unstake them to see them here.
                    </>
                  )}
                </Text>
              </FooterContainer>
            </AutoColumn>
          </AutoColumn>
        </AutoColumn>
      </AppBody>
    </>
  )
}
